<template>
 <virtual-selector
    :renderKey="renderKey"
    :isLoading="loading"
    :placeholder="placeholder"
    v-model="defaultValue"
    :name.sync="defaultName"
    :optionList="optionList"
    :readonly="readonly"
    filterable
    remote
    :option="{
      label: 'name',
      key: 'id',
    }"
    :size="size"
    :remoteMethod="handleSearch"
    :load-more="loadMore"
    @change="handleChange">
  </virtual-selector>
</template>
<script>
import axios from 'axios';
import VirtualSelector from '@/components/common/virtualSelector';
import CustomerApi from '@/api/customer';
export default {
  name: 'CONSTOMER_SELECTOR',
  components: {
    VirtualSelector,
  },
  props: {
    renderKey: {
      typr: String,
    },
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    source: {
      type: String,
      default: 'HOSPITAL_LIBRARY',
    },
    placeholder: {
      type: String,
      default: '请选择医院',
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultValue: this.value,
      defaultName: this.name,
      pageIndex: 1,
      isReachLast: false,
    };
  },
  watch: {
    value () {
      this.defaultValue = this.value;
    },
    name () {
      this.defaultName = this.name;
    },
  },
  methods: {
    getList (keyword, pageSize = 10, value) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.loading = true;
      CustomerApi.getCustomerList({
        name: keyword,
        status: 'EFFECTIVE',
        pageNum: this.pageIndex,
        pageSize: pageSize,
        isPermissions: false,
        isIndate: true,
        customerSource: this.source,
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      }).then(res => {
        this.loading = false;
        this.isReachLast = (res.body?.records?.length || 0) < 10;
        this.optionList = this.optionList.concat(res?.body?.records || []);
        if (value) {
          const selected = this.optionList.find(item => value === item.id);
          if (selected.id) {
            this.handleChange(selected);
          }
        }
      }).catch((res) => {
        if (res?.heads?.code !== -100) {
          this.loading = false;
        }
      });
    },
    handleChange (item) {
      this.$emit('input', item?.id);
      this.$emit('update:name', item?.name);
      this.$emit('change', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.optionList.splice(0, this.optionList.length);
      this.getList(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getList(keyword);
      }
    },
  },
  mounted () {
    if (this.value) {
      this.getList('', 999, this.value);
    }
  },
};

</script>
